<template>
  <b-modal
    v-model="showed"
    size="xl"
    id="asset-modal"
    @hidden="$emit('asset-closed')"
  >
    <pdf
      v-if="showAsset"
      v-show="loadedRatio == 1"
      :style="style"
      ref="pdf"
      :title="name"
      :page="page"
      :src="formatedSrc"
      @progress="loadedRatio = $event"
      @num-pages="pageCount = $event"
      id="pdf"
    />
    <template v-slot:modal-header="{ close }">
      <b-container>
        <b-row align-h="center">
          <b-col lg="2" class="text-center">
            <b-button
              alt="Previous page"
              @click="prevPage()"
              block
              :variant="page <= 1 ? 'outline-secondary' : 'light-primary'"
              :disabled="page <= 1"
            >
              <i class="fa fa-chevron-left"></i>
            </b-button>
          </b-col>
          <b-col lg="1">
            <b-button disabled variant="outline-secondary">
              <span class="font-weight-boldest">{{ page }}</span> /
              {{ pageCount }}
            </b-button>
          </b-col>
          <b-col lg="2" class="text-center">
            <b-button
              alt="Next page"
              @click="nextPage()"
              block
              :variant="
                page >= pageCount ? 'outline-secondary' : 'light-primary'
              "
              :disabled="page >= pageCount"
            >
              <i class="fa fa-chevron-right"></i>
            </b-button>
          </b-col>
          <b-col lg="1" offset-lg="1" class="text-center">
            <b-button
              alt="Zoom In"
              @click="zoomIn()"
              block
              :variant="zoom >= 100 ? 'outline-secondary' : 'light-primary'"
              :disabled="zoom >= 100"
            >
              <i class="fa fa-search-plus"></i>
            </b-button>
          </b-col>
          <b-col lg="1" class="text-center">
            <b-button
              alt="Zoom out"
              @click="zoomOut()"
              block
              :variant="zoom <= 40 ? 'outline-secondary' : 'light-primary'"
              :disabled="zoom <= 40"
            >
              <i class="fa fa-search-minus"></i>
            </b-button>
          </b-col>
          <b-col lg="1" offset-lg="1" class="text-center">
            <b-button @click="download()" block variant="light-primary">
              <i class="fa fa-download"></i>
            </b-button>
          </b-col>
          <b-col lg="1" class="text-center">
            <b-button @click="close()" block variant="light-primary">
              <i class="fa fa-window-close"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:modal-footer="{ close }">
      <b-container>
        <b-row align-h="center">
          <b-col lg="2" class="text-center">
            <b-button
              alt="Previous page"
              @click="prevPage()"
              block
              :variant="page <= 1 ? 'outline-secondary' : 'light-primary'"
              :disabled="page <= 1"
            >
              <i class="fa fa-chevron-left"></i>
            </b-button>
          </b-col>
          <b-col lg="1">
            <b-button disabled variant="outline-secondary">
              <span class="font-weight-boldest">{{ page }}</span> /
              {{ pageCount }}
            </b-button>
          </b-col>
          <b-col lg="2" class="text-center">
            <b-button
              alt="Next page"
              @click="nextPage()"
              block
              :variant="
                page >= pageCount ? 'outline-secondary' : 'light-primary'
              "
              :disabled="page >= pageCount"
            >
              <i class="fa fa-chevron-right"></i>
            </b-button>
          </b-col>
          <b-col lg="1" offset-lg="1" class="text-center">
            <b-button
              alt="Zoom In"
              @click="zoomIn()"
              block
              :variant="zoom >= 100 ? 'outline-secondary' : 'light-primary'"
              :disabled="zoom >= 100"
            >
              <i class="fa fa-search-plus"></i>
            </b-button>
          </b-col>
          <b-col lg="1" class="text-center">
            <b-button
              alt="Zoom out"
              @click="zoomOut()"
              block
              :variant="zoom <= 40 ? 'outline-secondary' : 'light-primary'"
              :disabled="zoom <= 40"
            >
              <i class="fa fa-search-minus"></i>
            </b-button>
          </b-col>
          <b-col lg="1" offset-lg="1" class="text-center">
            <b-button @click="download()" block variant="light-primary">
              <i class="fa fa-download"></i>
            </b-button>
          </b-col>
          <b-col lg="1" class="text-center">
            <b-button @click="close()" block variant="light-primary">
              <i class="fa fa-window-close"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import pdf from "vue-pdf";
import VueScrollTo from "vue-scrollto";

export default {
  name: "Pdf",
  components: {
    pdf: pdf
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      showed: this.showAsset,
      zoom: 100,
      loadedRatio: 0
    };
  },
  props: {
    base64: {
      type: String,
      required: true
    },
    showAsset: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: "Kymatio.pdf"
    }
  },
  methods: {
    nextPage() {
      if (this.pageCount > this.page) {
        VueScrollTo.scrollTo(".modal-header", 500, {
          container: "#asset-modal"
        });
        this.page++;
      }
    },
    prevPage() {
      if (this.page > 1) {
        VueScrollTo.scrollTo(".modal-header", 500, {
          container: "#asset-modal"
        });
        this.page--;
      }
    },
    download() {
      const linkSource = `data:application/pdf;base64,${this.base64}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = this.name;
      downloadLink.click();
    },
    zoomIn() {
      if (this.zoom <= 100) {
        this.zoom += 10;
      }
    },
    zoomOut() {
      if (this.zoom >= 20) {
        this.zoom -= 10;
      }
    }
  },
  computed: {
    style() {
      return `width: ${this.zoom}%; height: ${this.zoom}%; margin-left: auto; margin-right: auto;`;
    },
    formatedSrc() {
      var base64ToString =
        process.env.VUE_ENV === "server"
          ? function(base64) {
              return Buffer.from(base64, "base64").toString();
            }
          : window.atob;

      // get the binary version of the pdf
      var binaryVersionOfThePdf = base64ToString(this.base64);
      return { data: binaryVersionOfThePdf };
    }
  },
  watch: {
    showAsset(val) {
      if (!val) {
        this.page = 1;
        this.pageCount = 0;
        this.zoom = 100;
      } else {
        this.showed = true;
      }
    }
  }
};
</script>
