<template>
  <b-modal
    v-model="showed"
    size="xl"
    id="asset-modal"
    @hidden="$emit('asset-closed')"
  >
    <template v-slot:modal-header="{ close }">
      <b-container>
        <b-row align-h="end">
          <b-col lg="1" class="text-center">
            <b-button @click="close()" block variant="light-primary">
              <i class="fa fa-window-close"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:modal-footer="{ close }">
      <b-container>
        <b-row align-h="end">
          <b-col lg="1" class="text-center">
            <b-button @click="close()" block variant="light-primary">
              <i class="fa fa-window-close"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container class="htmlModal">
      <b-row>
        <b-col cols="12" v-html="code" />
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "Html",
  components: {},
  data() {
    return {
      showed: this.showAsset
    };
  },
  props: {
    code: {
      type: String,
      required: true
    },
    showAsset: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  computed: {},
  watch: {
    showAsset(val) {
      if (val) {
        this.showed = true;
      }
    }
  }
};
</script>

<style lang="scss">
.htmlModal .title {
  font-size: 26pt;
  text-align: center;
  line-height: 150%;
  font-weight: normal;
}

.htmlModal .subtitle {
  font-size: 21pt;
  text-align: center;
  line-height: 150%;
  font-weight: normal;
}

.htmlModal .text {
  font-size: 13pt;
  text-align: justify;
  line-height: 150%;
}

.htmlModal .list-item {
  font-size: 13pt;
  text-align: justify;
  line-height: 150%;
  list-style-type: disc;
}

.htmlModal .image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
