<template>
  <div class="youtubes">
    <div class="m-2" v-if="error">
      <h1>NO SE HA PODIDO REPRODUCIR EL VÍDEO</h1>
      <i class="fa fa-bug fa-10x"></i>
    </div>
    <video
      v-if="!error"
      ref="videoPlayer"
      :class="'video-js '"
      width="500"
      height="500"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";
import axios from "axios";

export default {
  name: "OwnVideo",
  components: {},
  data() {
    return {
      player: null,
      error: false,
      messageError: {},
      showVideo: 0,
      isYoutube: true,
      techOrder: []
    };
  },
  computed: {
    options() {
      return {
        autoplay: true,
        controls: true,
        techOrder: this.techOrder,
        sources: [
          {
            type: this.buildVideoType(this.isYoutube),
            src: this.buildVideoSrc(this.isYoutube),
            withCredentials: false
          }
        ],
        html5: { hls: { withCredentials: false } },
        flash: { hls: { withCredentials: false } }
      };
    },
    mustBeShown() {
      return this.showVideo;
    }
  },
  methods: {
    buildVideoSrc(error) {
      return error
        ? "https://www.youtube.com/watch?v=" + this.code
        : "https://dkdzifbe1uos6.cloudfront.net/" +
            this.code +
            "/" +
            this.code +
            ".m3u8";
    },
    buildVideoType(error) {
      return error ? "video/youtube" : "application/x-mpegURL";
    }
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  mounted() {
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    if (window.YT) {
      this.techOrder.push("youtube");
      this.player = videojs(this.$refs.videoPlayer, this.options);
      this.player.on("error", () => {
        this.isYoutube = false;
        this.techOrder.push("html5");
        this.player.src({
          type: this.buildVideoType(false),
          src: this.buildVideoSrc(false),
          withCredentials: false
        });

        this.player.play();
      });
    } else {
      this.isYoutube = false;
      axios
        .get(
          "https://dkdzifbe1uos6.cloudfront.net/" +
            this.code +
            "/" +
            this.code +
            ".m3u8"
        )
        .then(() => {
          this.isYoutube = false;
          this.techOrder.push("html5");
          this.player = videojs(this.$refs.videoPlayer, this.options);
          this.player.on("error", () => {
            this.error = true;
          });
        })
        .catch(() => {
          this.error = true;
        });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style lang="scss" scoped>
.youtubes {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  text-align: center;
  .video-js,
  iframe {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
}
</style>
