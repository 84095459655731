<template>
  <div class="youtubes">
    <div style="position:relative;height:0;padding-bottom:56.25%">
      <iframe
        :src="`https://embed.ted.com/talks/lang/es/${code}`"
        width="854"
        height="480"
        style="position:absolute;left:0;top:0;width:100%;height:100%"
        frameborder="0"
        scrolling="no"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ted",
  components: {},
  props: {
    code: {
      type: String,
      required: true
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.youtubes {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  text-align: center;
  iframe {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
}
</style>
