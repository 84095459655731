<template>
  <div class="iframes">
    <iframe
      :src="code"
      width="100%"
      height="360"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Iframe",
  components: {},
  props: {
    code: {
      type: String,
      required: true
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.youtubes {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  text-align: center;
  iframe {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
}
</style>
