<template>
  <div class="image">
    <b-img :src="code" fluid></b-img>
  </div>
</template>

<script>
export default {
  name: "Image",
  components: {},
  props: {
    code: {
      type: String,
      required: true
    }
  },
  mounted() {}
};
</script>
