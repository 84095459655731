<template>
  <div class="youtubes">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";

export default {
  name: "OwnVideo",
  components: {},
  data() {
    return {
      player: null
    };
  },
  computed: {
    options() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            type: "application/x-mpegURL",
            src: this.code,
            withCredentials: false
          }
        ],
        html5: { hls: { withCredentials: false } },
        flash: { hls: { withCredentials: false } }
      };
    }
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style lang="scss" scoped>
.youtubes {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  text-align: center;
  .video-js {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
  }
}
</style>
